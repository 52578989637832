<!--
 * @Author: awenyjw
 * @Date: 2023-04-10 13:30:28
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-06-16 17:37:28
 * @FilePath: \hc-smartSupervision\src\views\system\index\welcome.vue
 * @Description: 
 * 
-->
<!-- 首页 -->
<template>
  <div id="Home">
    <div v-if="admintype==1">
      超管
    </div>

    <!-- 公司首页 -->
    <company v-if="admintype==2"></company>
    <project v-if="admintype==3" />
    
  </div>
</template>

<script>

import company from './components/company.vue'
import project from './components/project.vue'
export default {
  data() {
    return {
      activeName: 'project',
      admintype:this.$store.state.user.admintype
    }
  },
  components: {
    project,
    company
  },
  mounted() { },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style lang="scss" scoped>
.home-Main {
  width: 100%;
  border-radius: 5px;
  margin-top: 0.15rem;
  overflow: hidden;
}

#Home {
  width: 100%;
  overflow-y: hidden;
}

.el-col-8 {
  padding-left: 0.21rem;
}

.tab-text {
  vertical-align: middle;
}
</style>
